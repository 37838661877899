import axios from 'axios';
import { loginService } from './LoginService';

export const articleService = {
  saveArticle,
  getArticles
}

function saveArticle(article) {
  let url = `${process.env.REACT_APP_API_URL}/api/articles${
    article.id ? `/${article.id}` : ''
  }`;

  const requestOptions = {
    method: article.id ? 'PUT' : 'POST',
    url,
    headers: {
      Accept: 'application/json'
    },
    data: createBodyRequest(article)
  };

  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] = loginService.getToken();
  }

  return axios(requestOptions);
}

function getArticles(pageNumber) {
  let url = `${process.env.REACT_APP_API_URL}/api/articles?page[number]=${
    pageNumber ? pageNumber : 1
  }`;

  const requestOptions = {
    headers: {
      Accept: 'application/json'
    }
  };

  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] = loginService.getToken();
  }

  return axios.get(url, requestOptions);
}

function createBodyRequest(article) {
  let formData = new FormData();

  formData.append('article[title]', article.title);
  formData.append('article[slug]', article.slug);
  formData.append('article[description]', article.description);

  article.articleSections.map(articleSection => {
    formData.append('article[article_sections_attributes][][tip]', articleSection.type);
    formData.append('article[article_sections_attributes][][component]', articleSection.component);
    formData.append('article[article_sections_attributes][][fe_component]', articleSection.feComponent);
    formData.append('article[article_sections_attributes][][value]', articleSection.value);

    if (articleSection.type === 'image') {
      const image = articleSection.image;

      if (image) {
        if (image.url) {
          formData.append(`article[article_sections_attributes][][image]`, image.url);
        } else {
          formData.append(`article[article_sections_attributes][][image]`, image, image.name);
        }
      }

    }
  });

  const main_image = article.main_image;

  if (main_image) {
    if (main_image.url) {
      formData.append(`article[main_image]`, main_image.url);
    } else {
      formData.append(`article[main_image]`, main_image, main_image.name);
    }
  }

  return formData;
}
