const ArticleSubtitleSection = {
  type: 'subTitle',
  component: 'input',
  feComponent: 'h3',
  value: '',
  label: 'Adauga subtitlu'
};

const ArticleParagraphSection = {
  type: 'paragraf',
  component: 'textarea',
  feComponent: 'p',
  value: '',
  label: 'Adauga paragraf'
};

const ArticleImageSection = {
  type: 'image',
  component: 'dropzone',
  feComponent: 'img',
  value: '',
  image: '',
  label: 'Adauga imagine'
};

const ArticleVideoURLSection = {
  type: 'videoUrl',
  component: 'input',
  feComponent: 'video',
  value: '',
  label: 'Adauga Url Video'
};

const articleSectionTypes = [
  ArticleSubtitleSection,
  ArticleParagraphSection,
  ArticleImageSection,
  ArticleVideoURLSection
]

export default articleSectionTypes;
