import React from 'react';
import './AddArticle.scss';
import AttachementField from '../../components/AttachementField/AttachementField';
import Header from '../../components/Header/Header.jsx';
import { articleService } from '../../service/ArticleService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import articleSectionTypes from '../../constants/ArticleSections';

class AddArticle extends React.Component{
    constructor(props){
        super(props)
        this.state={
            article:{
                title:'',
                slug:'',
                main_image:'',
                description: '',
                articleSections:[]
            },
            disabledDocument: false
        }

        this.notificationSystem = React.createRef();
    }
    onDropDocument = documents => {
        let article = { ...this.state.article };
        let reader = new FileReader();
        let self = this;

        reader.onload = function(upload) {
            article.main_image = documents[0];

          self.setState({ article });
          self.setState({ disabledDocument: true });
        };
        reader.readAsDataURL(documents[0]);
      };

    onDropImageDocument = (documents, rejected, index) => {
        let article = { ...this.state.article };
        let reader = new FileReader();
        let self = this;

        reader.onload = function(upload) {
          let currentSection = article.articleSections[index];

          currentSection.image = documents[0];

          self.setState({ article });
        };
        reader.readAsDataURL(documents[0]);
      };

    removeDocument = () => {
      let article = { ...this.state.article };
      article.main_image = null;
      article.main_image_filename = '';

      this.setState({ article });
      this.setState({ disabledDocument: false });
    };

    removeImageDocument = index => {
      let article = { ...this.state.article };
      let currentSection = article.articleSections[index];
      currentSection.image = null;
      currentSection.image_filename = '';

      this.setState({ article });
    };

    updateField = event => {
        const field = event.target.name;
        const article = { ...this.state.article };
        article[field] = event.target.value;
        this.setState({
            article
        });
      };

      updateSectionField = (event, index) => {
        let article = this.state.article;

        let currentSection = article.articleSections[index];
        currentSection.value = event.target.value;

        this.setState({ article })
      }

    addSection= type =>{
      const section = Object.assign({}, articleSectionTypes.find(articleSection => articleSection.type === type));
      let article = this.state.article;

      article.articleSections.push(section);
      this.setState({ article });
    }

    removeSection= index => {
      let article = this.state.article;
      article.articleSections.splice(index, 1);
      this.setState({ article });
    }

    saveArticle = event => {
      event.preventDefault();
      const notification = this.notificationSystem.current;

      articleService.saveArticle(this.state.article)
        .then(response => {
          notification.addNotification({
            message: 'Articolul a fost adaugat cu success.',
            level: 'success',
            position: 'tc'
          });
        })
        .catch(error => {
          notification.addNotification({
            message: 'A aparut o problema la adaugarea articolului.',
            level: 'error',
            position: 'tc'
          });
        })
    }
    render(){
        return(
            <div className='standard add'>
              <Header/>
                <div className='add__container'>
                    <div className='add__left-section'>
                        <h2 className='add__actions'>ACȚIUNI</h2>
                        <button className='add__add-button' onClick={() => this.addSection('subTitle')}>
                            <h5 className='add__indication' >ADAUGĂ SUBTITLU</h5>
                        </button>
                        <button className='add__add-button' onClick={() => this.addSection('paragraf')}>
                            <h5 className='add__indication'>ADAUGĂ PARAGRAF</h5>
                        </button>
                        <button className='add__add-button' onClick={() => this.addSection('image')}>
                            <h5 className='add__indication'>ADAUGĂ IMAGINE</h5>
                        </button>
                        <button className='add__add-button' onClick={() => this.addSection('videoUrl')}>
                            <h5 className='add__indication'>ADAUGĂ URL VIDEO</h5>
                        </button>
                    </div>
                    <div className='add__right-section'>
                        <h3 className='add__title'>Adaugă un articol</h3>
                        <form className='add__form' onSubmit={this.saveArticle}>
                            <h4 className='add__label'>Adaugă titlul:</h4>
                            <input
                                    className='add__input'
                                    type="text"
                                    name="title"
                                    onChange={this.updateField}
                                    value={this.state.article.title}
                                    placeholder="Title"/>

                            <h4 className='add__label'>Adaugă slug:</h4>
                            <input
                                    className='add__input'
                                    type="text"
                                    name="slug"
                                    onChange={this.updateField}
                                    value={this.state.article.slug}
                                    placeholder="Slug"/>
                            <div>
                            <h4 className='add__label'>Adaugă imagine principala articol:</h4>
                            <AttachementField
                              onDrop={this.onDropDocument}
                              removeDocument={this.removeDocument}
                              disabledDocuments={this.state.disabledDocument}
                              document={this.state.article.main_image}
                              documentFilename={
                                  this.state.article.main_image
                              }
                              type="image/*"
                            />
                            <h4 className='add__label'>Adauga descriere/brief</h4>
                            <div className='add__input-box'>
                              <textarea
                                  rows="5"
                                  className='add__input'
                                  name="description"
                                  onChange={this.updateField}
                                  value={this.state.article.description}
                                />
                            </div>
                            {this.state.article.articleSections.map((articleSection, index) =>(
                              <div key={index} className='add__input-box'>
                                  <h4 className='add__label'>{articleSection.label}</h4>
                                  <div className='add__input-wrapper'>
                                    { articleSection.component === 'input' && (
                                      <input
                                          className='add__input'
                                          type="text"
                                          onChange={event => this.updateSectionField(event, index)}
                                          value={articleSection.value}
                                          />
                                    )}
                                    { articleSection.component === 'textarea' && (
                                      <textarea
                                          rows="5"
                                          className='add__input'
                                          onChange={event => this.updateSectionField(event, index)}
                                          value={articleSection.value}
                                        />
                                    )}
                                    { articleSection.component === 'dropzone' && (
                                      <AttachementField
                                        onDrop={this.onDropImageDocument}
                                        removeDocument={this.removeImageDocument}
                                        disabledDocuments={this.state.disabledImageDocument}
                                        currentArticleSectionIndex={index}
                                        document={articleSection.image}
                                        documentFilename={
                                            articleSection.image
                                        }
                                        type="image/*"
                                      />
                                    )}
                                      <img src='/images/close-icon-black.png' className='add__remove-input' alt='remove' onClick={() => this.removeSection(index)}/>
                                  </div>
                              </div>
                            ))}
                      </div>
                        <button
                            type="submit"
                            className="add__button"
                        >
                            <h5 className="add__button-writing">Adaugă articol</h5>
                        </button>
                      </form>
                    </div>
                </div>
                <NotificationSystem
                  ref={this.notificationSystem}
                  style={NotificationSystemStyle}
                />
            </div>
        )
    }
}

export default AddArticle;
