import React from 'react';
import './Login.scss';
import { loginService } from '../../service/LoginService.jsx';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';

class LogIn extends React.Component{
    constructor(props){
        super(props);

        this.state={
            email:"",
            password:""
        }
        this.notificationSystem = React.createRef();
    }

    handleChange=event=>{
        this.setState({
            [event.target.name]:event.target.value
        })
    }

    componentWillMount() {
      if (loginService.loggedIn()) {
        this.props.history.push('/all-articles');
      }
    }

    handleLogin = event => {
      event.preventDefault();
      const notification = this.notificationSystem.current;

      loginService
        .login(this.state)
        .then(response => {
          loginService.setToken(response.headers.authorization);
          this.props.history.push('/all-articles');
        })
        .catch(error => {
          notification.addNotification({
            message: 'Email sau parola incorecte.',
            level: 'error',
            position: 'tc'
          });
        });
    }

    render(){
        return(
            <div className='standard login'>
                    <div className='login__container'>
                        <h3 className='standard__section-title login__title'>Acces Blogul Tău</h3>
                        <form className='login__form' onSubmit={this.handleLogin}>
                              <input
                                className='login__input'
                                type="email"
                                name="email"
                                onChange={this.handleChange}
                                required
                                value={this.state.email}
                                placeholder="Email"/>

                                <input
                                className='login__input'
                                type="password"
                                name="password"
                                onChange={this.handleChange}
                                required
                                value={this.state.password}
                                placeholder="Parola"/>
                            <div className='login__submit-box'>
                                <button type='submit' className='login__submit-button'><h5 className='login__button-writing'>INTRĂ ÎN PLATFORMĂ</h5></button>
                                <h5 className='login__password-question'>AI UITAT PAROLA?</h5>
                            </div>
                        </form>

                    </div>
                    <NotificationSystem
                      ref={this.notificationSystem}
                      style={NotificationSystemStyle}
                    />
            </div>
        )
    }
}

export default LogIn;
