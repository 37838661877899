import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import './App.css';
import Login from '../src/containers/Login/Login';
import AllArticles from '../src/containers/AllArticles/AllArticles';
import AddArticle from '../src/containers/AddArticle/AddArticle';
import { loginService } from '../src/service/LoginService.jsx';

class App extends React.Component{
  render(){
    return(
      <div>
        <Router>
            <div>
              <DefaultRoute exact path="/login" component={Login}/>
              <PrivateRoute exact path="/all-articles" component={AllArticles}/>
              <PrivateRoute exact path="/add-article" component={AddArticle}/>
            </div>
        </Router>
      </div>
    )
  }

}
const DefaultRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={matchProps => (
        <Component {...matchProps} />
    )}
  />
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      loginService.loggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

export default App;
